import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./components/pages/Footer/Footer";
import Share from "./components/pages/Share/Share";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Share} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
