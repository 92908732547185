export const shareObjOne = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    extraPad: true,
    headline: 'Want to play at home?',
    description:
      'Omni One is the first Omni optimized for the home and offers unmatched freedom of movement including crouching and jumping.',
    description2: '',
    noButton: '',
    buttonLabel: 'Check out Omni One',
    buttonLink: 'https://omni.virtuix.com/',
    imgStart: 'start',
    img: '/images/omniplayer_share_omnione_run.jpg',
    alt: 'Omni One Room'
  };

  export const locationSectionLightBG = {
    lightBg: true,
  };