import React, { useEffect, useState } from "react";
import "./SharePlayer.css";
import { Link } from "react-router-dom";
import {
  ImageModal1,
  ImageModal2,
  ImageModal3,
  ImageModal4,
} from "./ImageModal";
import {
  ShareTikTokModal,
  ShareInstagramModal,
} from "./ShareModal";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

export function SharePlayer() {
  const [imageShow1, setImageShow1] = useState(false);
  const [imageShow2, setImageShow2] = useState(false);
  const [imageShow3, setImageShow3] = useState(false);
  const [imageShow4, setImageShow4] = useState(false);
  const [shareTikTokShow, setShareTikTokShow] = useState(false);
  const [shareInstagramShow, setShareInstagramShow] = useState(false);

  var currentUrl = document.URL;
  const fbUrl = currentUrl;
  const twitUrl = currentUrl;
  const linkedUrl = currentUrl;
 
  const [venue, setVenue] = useState();
  useEffect(() => {
    fetch(
      "https://api.playerhub.omniverse.global/social/" +
        window.location.search.substring(4) +
        "/info"
    )
      .then((result) => result.json())
      .then((result) => {
        setVenue(result.shopName);
      });
  }, []);

  const downloadUrl = "https://api.playerhub.omniverse.global/social/" + window.location.search.substring(4) + "/download-video";

  const playMessage = "Watch me play Omni Arena at " + String(venue) + "! #omniarena";

  return (
    <>
      <div className="share-player-container">
        <video
          poster={
            "https://content.omniverse.global/media/" +
            window.location.search.substring(4) +
            "/FirstFrame.png"
          }
          onError={(e)=>{ if (e.target.poster !== "https://share.virtuix.com/images/arena_no_video.jpg"){
              e.target.poster="https://share.virtuix.com/images/arena_no_video.jpg";}
            }}
          controls
          className="share-player"
        >
          <source
            src={
              "https://content.omniverse.global/media/" +
              window.location.search.substring(4) +
              "/processedVideo.mp4"
            }
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <div className="share-image-container">
          <img
            className="share-image"
            src={
              "https://content.omniverse.global/media/" +
              window.location.search.substring(4) +
              "/snapshot1.png"
            }
            onError={(e)=>{ if (e.target.src !== "https://share.virtuix.com/images/arena_no_img.png"){
              e.target.src="https://share.virtuix.com/images/arena_no_img.png";}
            }
      }
            alt="Screenshot"
            onClick={() => setImageShow1(true)}
          />
          <img
            className="share-image"
            src={
              "https://content.omniverse.global/media/" +
              window.location.search.substring(4) +
              "/snapshot2.png"
            }
            onError={(e)=>{ if (e.target.src !== "https://share.virtuix.com/images/arena_no_img.png"){
              e.target.src="https://share.virtuix.com/images/arena_no_img.png";}
            }
      }
            alt="Screenshot"
            onClick={() => setImageShow2(true)}
          />
          <img
            className="share-image"
            src={
              "https://content.omniverse.global/media/" +
              window.location.search.substring(4) +
              "/snapshot3.png"
            }
            onError={(e)=>{ if (e.target.src !== "https://share.virtuix.com/images/arena_no_img.png"){
              e.target.src="https://share.virtuix.com/images/arena_no_img.png";}
            }
      }
            alt="Screenshot"
            onClick={() => setImageShow3(true)}
          />
          <img
            className="share-image"
            src={
              "https://content.omniverse.global/media/" +
              window.location.search.substring(4) +
              "/snapshot4.png"
            }
            onError={(e)=>{ if (e.target.src !== "https://share.virtuix.com/images/arena_no_img.png"){
              e.target.src="https://share.virtuix.com/images/arena_no_img.png";}
            }
      }
            alt="Screenshot"
            onClick={() => setImageShow4(true)}
          />
        </div>
        <div className="share-share-container">
          <h2 className="share-share-header">Share your gameplay video:</h2>
          <div className="share-share-social">
            <FacebookShareButton
              className="share-share-icon-link"
              url={fbUrl}
              quote={playMessage}              
            >
              <img
                className="share-share-icon-img"
                src="/images/facebook_now_icon.svg"
                alt="Share to Facebook"
              />
            </FacebookShareButton>
            <Link
              className="share-share-icon-link share-share-icon-mobile"
              aria-label="Instagram"
              onClick={() => setShareInstagramShow(true)}
            >
              <img
                className="share-share-icon-img"
                src="/images/instagram_now_icon.svg"
                alt=""
              />
            </Link>
            <LinkedinShareButton
              className="share-share-icon-link"
              title={playMessage}
              summary={playMessage}
              url={linkedUrl}
            >
              <img
                className="share-share-icon-img"
                src="/images/linkedin_now_icon.svg"
                alt=""
              />
            </LinkedinShareButton>
            <TwitterShareButton
              className="share-share-icon-link"
              title={playMessage}
              url={twitUrl}
            >
              <img
                className="share-share-icon-img"
                src="/images/twitter_now_icon.svg"
                alt=""
              />
            </TwitterShareButton>
            <Link
              className="share-share-icon-link share-share-icon-mobile"
              aria-label="TikTok"
              onClick={() => setShareTikTokShow(true)}
            >
              <img
                className="share-share-icon-img"
                src="/images/tiktok_now_icon_share.svg"
                alt=""
              />
            </Link>
            <a href={downloadUrl} download>
              <img
                className="share-share-icon-img"
                src="/images/download_now_icon.svg"
                alt=""
              />
              </a>
          </div>
        </div>
      </div>
      <ImageModal1
        title="Omni Arena Screenshot"
        onClose={() => setImageShow1(false)}
        show={imageShow1}
      />
      <ImageModal2
        title="Omni Arena Screenshot"
        onClose={() => setImageShow2(false)}
        show={imageShow2}
      />
      <ImageModal3
        title="Omni Arena Screenshot"
        onClose={() => setImageShow3(false)}
        show={imageShow3}
      />
      <ImageModal4
        title="Omni Arena Screenshot"
        onClose={() => setImageShow4(false)}
        show={imageShow4}
      />
      <ShareTikTokModal
        title="Share Instructions"
        onClose={() => setShareTikTokShow(false)}
        show={shareTikTokShow}
      />
      <ShareInstagramModal
        title="Share Instructions"
        onClose={() => setShareInstagramShow(false)}
        show={shareInstagramShow}
      />
    </>
  );
}

export default SharePlayer;
